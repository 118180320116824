import * as React from "react";
import {Helmet} from "react-helmet";
import {Navigation} from "../common/navigation";

export class HeaderBtrn extends React.Component<{}, {}> {
    render() {
        return (
            <>
                <Helmet>
                    <title>Blockchain &amp; BTRN token</title>
                </Helmet>

                <Navigation selected="btrn"/>

                <header className="biotron-token">
                    <div className="bg-token">
                        <div className="wrapper main-title">

                        <h1 className="title bold white left">
                            Blockchain &amp; Token
                        </h1>
                            <span className="btrn semi-bold left">BTRN</span>
                        </div>
                    </div>
                </header>

                <span className="clear"></span>
            </>
        );
    }
}
