import * as React from "react";
import { Helmet } from "react-helmet";
import { Navigation } from "../common/navigation";
import { LetsTalkProps } from "../common/letsTalk";

const homeHeader = require("../../../src/assets/header-home.svg");

export interface HeaderHomeProps {
    isModalActive: boolean;
    setModalState(step: boolean);
}
export class HeaderHome extends React.Component<HeaderHomeProps, {}> {

    constructor(props: LetsTalkProps) {
        super(props);
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Biotron.io - Innovation Through Data</title>
                </Helmet>

                <Navigation selected="home" />

                <header className="homepage">
                    <div className="wrapper">
                        <div className="homepage-title-header">
                            <div className="block-left">
                                <h1 className="title bold">Biotron Foundation</h1>
                            </div>

                            <div className="block-right">
                                <img className="header-img" src={homeHeader} />
                            </div>
                        </div>
                    </div>

                    {/* <div className="wrapper developer-update">
                        <a className="bla" href="https://medium.com/biotron/biotron-announces-outlook-for-2021-and-revisits-last-year-5b17ca44db9a" target="_blank">
                            <span className="text update">Outlook for 2021</span>
                        </a>

                        <span className="text latest">Latest News</span>
                    </div> */}

                    <span className="clear"></span>

                    <div id="example"> </div>
                </header>

                <span className="clear"></span>
            </>
        );
    }
}
