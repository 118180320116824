import * as React from "react";
import { Helmet } from "react-helmet";
import { LetsTalkProps } from "../common/letsTalk";
import { Navigation } from "../common/navigation";

export interface HeaderMembershipProps {
    isModalActive: boolean;
    setModalState(step: boolean);
}

export class HeaderMembership extends React.Component<HeaderMembershipProps, {}> {

    constructor(props: LetsTalkProps) {
        super(props);
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Membership</title>
                </Helmet>

                <Navigation selected="membership"/>

                <header className="homepage">
                    <div className="wrapper">
                        <div className="homepage-title-header">
                            <h1 className="title bold">Become a member</h1>
                            <p className="description">Apply for Biotron membership and take advantage of the benefits provided by the organization.</p>
                            <div className="button button-dark-blue" onClick={() => this.props.setModalState(true)}>
                                <span className="text bold">Apply now</span>
                                <span className="button-arrow" />
                            </div>
                        </div>
                    </div>
                </header>

                <span className="clear"></span>
            </>
        );
    }
}
