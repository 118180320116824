import * as React from "react";
import "../index.css";
import {TeamProps} from "./common/modalForm";
import CookieConsent from "react-cookie-consent";

export interface FooterState {}

export class Footer extends React.Component<{}, FooterState> {

    constructor(props: TeamProps) {
        super(props);
    }

    render() {
        return (
            <>
                <footer>
                    <div className="wrapper footer-contact bg-footer">
                        <div className="block-left tablet-block-left">
                            <div className="contact-info">
                                <div className="headquarters block-left">
                                    <p className="title">Contact</p>
                                    <p className="text">
                                        <a className="mail-to" href="mailto:hello@biotron.io">hello@biotron.io</a> <br />
                                    </p>
                                </div>
                                <div className="headquarters block-right">
                                    <p className="title">Headquarters</p>
                                    <p className="text">Stare Grunty 18 <br /> 841 04 Bratislava</p>
                                </div>
                            </div>
                            <span className="clear"></span>
                            <div className="social-links">
                                <a href="https://www.facebook.com/biotron.io/" target="_blank">
                                    <div className="item">
                                        <span className="facebook" />
                                    </div>
                                </a>
                                <a href="https://twitter.com/biotron_io" target="_blank">
                                    <div className="item">
                                        <span className="twitter" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper spacer"></div>
                    <div className="wrapper site-info bg-footer">
                        <div className="copyright">
                            <span>Biotron Foundation All Rights reserved.</span>
                        </div>
                        <div className="legals">
                            <a href="https://docs.biotron.io/Biotron_Terms_of_Use.pdf" target="_blank"><span className="terms">Terms of Use</span></a>
                            <a href="https://docs.biotron.io/Biotron_Privacy_Policy.pdf" target="_blank"><span className="privacy">Privacy Policy</span></a>
                            <a href="https://docs.biotron.io/Biotron_Privacy_Policy.pdf" target="_blank"><span className="cookies">Cookies</span></a>
                        </div>
                    </div>
                </footer>

                <CookieConsent
                    location="bottom"
                    buttonText="Yes, I agree."
                    cookieName="myAwesomeCookieName2"
                    style={{background: "#000786"}}
                    buttonStyle={{
                        padding: "5px 8px",
                        minWidth: "100px",
                        marginLeft: "5px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        color: "rgb(255, 255, 255)",
                        background: "rgb(242, 145, 50)",
                        textAlign: "center"
                    }}
                    expires={150}>
                    We use cookies to enhance your experience. By continuing to visit this site you agree to
                    <a href="https://docs.biotron.io/Biotron_Privacy_Policy.pdf" target="_blank"> our use of cookies.</a>
                </CookieConsent>
            </>
        );
    }
}
