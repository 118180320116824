import * as React from "react";
import * as ReactDOM from "react-dom";
import * as ReactGA from "react-ga";

import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "./index.css";

ReactGA.initialize("UA-112480161-3");
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <App />,
  document.getElementById("root") as HTMLElement
);
registerServiceWorker();
