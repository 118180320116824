import * as React from "react";
import { Route, Link, Switch } from "react-router-dom";
import { TeamProps } from "./modalForm";
import * as EmailValidator from "email-validator";
import { Contact } from "./contact";
import { ModalForm } from "./modalForm";

const biotronLogo = require("../../../src/assets/biotron_logo.svg");

export interface NavigationProps {
    selected: string;
}

interface NavigationState {
    backgroundType: string;
    backgroundTypeMobile: string;
    defaultMobileNav: string;
    isModalActive: boolean;
}

export class Navigation extends React.Component<NavigationProps, NavigationState> {

    constructor(props: NavigationProps) {
        super(props);

        this.state = {
            backgroundType: "basic",
            backgroundTypeMobile: "basic",
            defaultMobileNav: "topnav",
            isModalActive: false
        };
    }

    componentDidMount() {
        document.addEventListener("scroll", () => {
            const scrollPosition = window.scrollY;

            let background = "basic";
            let backgroundMobile = "basic";
            if (scrollPosition > 90) {
                background = "navi-back-blue";
            }

            if (scrollPosition > 100) {
                backgroundMobile = "";
            }


            this.setState({ backgroundType: background });
            this.setState({ backgroundTypeMobile: backgroundMobile });
        });
    }

    render(): JSX.Element {
        const { selected } = this.props;
        const { backgroundType, backgroundTypeMobile, defaultMobileNav } = this.state;
        // selected semi-bold
        return (
            <>
                <div className={defaultMobileNav + " " + backgroundTypeMobile}>
                    <Link to="/" className="biotron-logo" onClick={() => window.scrollTo(0, 0)}><img src={biotronLogo} /></Link>

                    <Link to="/"><span className={selected === "home" ? "item selected semi-bold" : "item"}>Home</span></Link>

                    <Link to="/blockchain">
                        <span className={selected === "btrn" ? "item selected semi-bold" : "item"}>Blockchain</span>
                    </Link>

                    <Link to="/membership">
                        <span className={selected === "membership" ? "item selected semi-bold" : "item"}>Membership</span>
                    </Link>

                    {/* <Link to="/foundation">
                            <span className={selected === "foundation" ? "item selected semi-bold" : "item"}>Foundation</span>
                    </Link> */}
                    {/* <Link to="/app">
                        <span className={selected === "app" ? "item selected semi-bold" : "item"}>App</span>
                    </Link> */}
                    {/* <a href="https://medium.com/biotron" target="_blank">
                        <span className="item">Blog</span>
                    </a> */}
                    {/* <a href="#products" onClick={this.navFunction}>
                        <span className="item">Products</span>
                    </a> */}
                    {/* <a href="#services" onClick={this.navFunction}>
                        <span className="item">Services</span>
                    </a>
                    <a href="#stories" onClick={this.navFunction}>
                        <span className="item">Success Stories</span>
                    </a>
                    <a href="#team" onClick={this.navFunction}>
                        <span className="item">Team</span>
                    </a> */}

                    {/* <Contact setModalState={this.setModalState} isModalActive={this.state.isModalActive} /> */}

                    <ModalForm isActive={this.state.isModalActive} setModalState={this.setModalState} />

                    <a href="https://medium.com/biotron" target="_blank">
                        <span className="item">News</span>
                    </a>

                    <a href="" className="icon" onClick={this.myFunction}>&#9776;</a>
                </div>

                <div className={backgroundType + " navibar"}>
                    <div className="wrapper navigation">
                        <div className="logo" onClick={() => window.scrollTo(0, 0)}>
                            <Link to="/"><img src={biotronLogo} /></Link>
                        </div>
                        <div className="nav-bar">
                            <Link to="/blockchain">
                                <span className={selected === "btrn" ? "item selected semi-bold" : "item"}>Blockchain</span>
                            </Link>

                            <Link to="/membership">
                                <span className={selected === "membership" ? "item selected semi-bold" : "item"}>Membership</span>
                            </Link>

                            {/* <Link to="/foundation">
                                <span className={selected === "foundation" ? "item selected semi-bold" : "item"}>Foundation</span>
                            </Link> */}
                            {/* <Link to="/app">
                                <span className={selected === "app" ? "item selected semi-bold" : "item"}>App</span>
                            </Link> */}
                            {/* <a href="#products">
                                <span className="item">Products</span>
                            </a> */}
                            {/* <a href="#services">
                                <span className="item">Services</span>
                            </a>
                            <a href="#stories">
                                <span className="item">Success Stories</span>
                            </a>
                            <a href="#team">
                                <span className="item">Team</span>
                            </a> */}

                            {/* <Contact setModalState={this.setModalState} isModalActive={this.state.isModalActive} /> */}

                            <ModalForm isActive={this.state.isModalActive} setModalState={this.setModalState} />

                            <a href="https://medium.com/biotron" target="_blank">
                                <span className="item">News</span>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    /* Toggle between adding and removing the "responsive" class to topnav when the user clicks on the icon */
    private myFunction = (e: React.FormEvent<any>) => {
        e.preventDefault();

        const { defaultMobileNav } = this.state;
        if (defaultMobileNav === "topnav") {
            this.setState({
                defaultMobileNav: defaultMobileNav + " responsive"
            });
        } else {
            this.setState({
                defaultMobileNav: "topnav"
            });
        }
    }

    /* Toggle between adding and removing the "responsive" class to topnav when the user clicks on the menu item */
    private navFunction = (e: React.FormEvent<any>) => {
        const { defaultMobileNav } = this.state;
        if (defaultMobileNav === "topnav") {
            this.setState({
                defaultMobileNav: defaultMobileNav + " responsive"
            });
        } else {
            this.setState({
                defaultMobileNav: "topnav"
            });
        }
    }

    private setModalState = (modalState: boolean) => {

        let isActive = !this.state.isModalActive;
        if (!modalState) {
            isActive = false;
        }

        this.setState({ isModalActive: isActive });
    }
}
