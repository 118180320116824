import * as React from "react";
import {HeaderMembership} from "../components/membership/HeaderMembership";
import {LetsTalk} from "../components/common/letsTalk";
import {ModalForm} from "../components/common/modalForm";

const accuracy = require("../../src/assets/accuracy.svg");
const network = require("../../src/assets/network.svg");
const quality = require("../../src/assets/quality.svg");

export interface ContentProps {
}

export interface MembershipState {
    isModalActive: boolean;
}

export class Membership extends React.Component<{}, MembershipState> {

    constructor(props: ContentProps) {
        super(props);

        window.scrollTo(0, 0);

        this.state = {
            isModalActive: false
        };
    }

    render(): JSX.Element {
        return (
            <>
                <HeaderMembership setModalState={this.setModalState} isModalActive={this.state.isModalActive}  />
                <div className="content homepage">
                    {/* Benefits */}
                    <div id="benefits" className="bg-blue-products-and-services products-and-services-section">
                        <div className="wrapper-simple header bg-blue-products-and-services">
                            <p className="products-title semi-bold white">
                                Benefits
                            </p>
                        </div>
                        <div className="content-wrapper products-and-services">
                            <div className="three-column-wrapper">
                                <div className="three-column-section">
                                    <span className="title-icon"><img src={network} /></span>
                                    <h2 className="title white">
                                        Education
                                    </h2>
                                    <p className="description desc-blue">
                                        We have expertise and experience with data science, product management,
                                        and legal in various industries. Our team supports projects to innovate
                                        through privacy-compliant and transparently collected data.
                                    </p>
                                </div>
                                <div className="three-column-section">
                                    <span className="title-icon"><img src={quality} /></span>
                                    <h2 className="title white">
                                        Tech sharing
                                    </h2>
                                    <p className="description desc-blue">
                                        We developed entire data platform for data collection, processing, analytics
                                        and user rewarding. Our technology stack and environment is already setup,
                                        so you can focus on delivering.
                                    </p>
                                </div>
                                <div className="three-column-section">
                                    <span className="title-icon"><img src={accuracy} /></span>
                                    <h2 className="title white">
                                        Legal consulting
                                    </h2>
                                    <p className="description desc-blue">
                                        We provide legal consulting to ensure that your projects are compliant with
                                        GDPR and other privacy regulations.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <LetsTalk setModalState={this.setModalState} isModalActive={this.state.isModalActive} />

                <ModalForm isActive={this.state.isModalActive} setModalState={this.setModalState}/>
            </>
        );
    }

    private setModalState = (modalState: boolean) => {

        let isActive = !this.state.isModalActive;
        if (!modalState) {
            isActive = false;
        }

        this.setState({isModalActive: isActive});
    }
}
